'use client';
import { QueryResponseInitial, useQuery } from '@sanity/react-loader';
import { SanityDocument, QueryParams } from 'next-sanity';
import React from 'react';
import { SetNavbarTextColor } from '@/components/global/modules';
import ComponentRenderer from './ComponentRenderer';
export default function PreviewPage({
  initial,
  params,
  query,
  breadcrumbs,
  navbarBgColor,
  navbarOverlay,
  children,
}: {
  initial: QueryResponseInitial<any>;
  params: QueryParams;
  query: any;
  breadcrumbs: any;
  navbarBgColor?: string;
  navbarOverlay?: string;
  children?: any;
}) {
  const { data: page } = useQuery<SanityDocument | null>(query, params, {
    initial,
  });

  const sections = page?.pageBuilder;

  return (
    <>
      {/* @ts-ignore */}
      <SetNavbarTextColor color={navbarBgColor} overlay={navbarOverlay} />
      <ComponentRenderer sections={sections} breadcrumbs={breadcrumbs}>
        {children}
      </ComponentRenderer>
    </>
  );
}
