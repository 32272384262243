'use client';
import { Button } from '@/components/shared/material';
import { useCallback, useState, useEffect } from 'react';
import { submitForm } from '@/actions';
import { Form } from '@/types/types';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import InputToolTip from '@/components/shared/form/InputToolTip';
import NewsletterEmailField from '@/components/shared/form/EmailField';
import { getForm } from '@/lib/hubspot/services/formService';
import * as Yup from 'yup';

interface FieldValidation {
  blockedEmailDomains: string[];
  useDefaultBlockList: boolean;
}

interface Field {
  objectTypeId: string;
  name: string;
  label: string;
  required: boolean;
  hidden: boolean;
  fieldType: string;
  validation: FieldValidation;
}

export default function NewsLetter(props: {
  formId: string;
  className?: string;
  children?: React.ReactNode;
}) {
  const [formResponse] = useState<{
    inlineMessage?: string;
    redirect?: string;
  } | null>(null);

  const [form, setForm] = useState<Form | null>(null);
  const [, setFields] = useState<Field[] | null>(null);

  useEffect(() => {
    if (props.formId) {
      getForm(props.formId).then((form) => {
        setForm(form);
        setFields(form as any);
      });
    }
  }, [props.formId]);

  const formTitle = form?.name;
  const cardTitle = 'Footer';

  const emailRegex = new RegExp(
    '^(?!.*\\#\\@)([a-zA-Z0-9]+([._\\-][a-zA-Z0-9]+)*)@(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})$'
  );
  // Email validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, 'Please enter a valid email address.')
      .max(250, 'Email address is too long.')
      .required('Email is required.'),
  });

  const handleSubmit = useCallback(
    async (values: any, { setSubmitting, resetForm }: any) => {
      setSubmitting(true);
      try {
        const pageURL = window.location.origin + window.location.pathname;
        const pageTitle = document.title;
        const response = await submitForm(form, values, pageURL, pageTitle);

        // If redirectUri is returned, redirect to that page
        if ('redirectUri' in response) {
          toast.success('Thank you. Redirecting you.', {
            position: 'bottom-center',
            icon: (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-4 w-4 animate-spin'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                />
              </svg>
            ),
            duration: 4000,
          });

          setTimeout(() => {
            window.location.href = response.redirectUri;
          }, 500);
          resetForm();
        }

        if ('inlineMessage' in response) {
          toast.success(response.inlineMessage, {
            position: 'bottom-center',
            duration: 4000,
          });
          resetForm();
        }

        if (response.status === 'error') {
          console.log('error', response);
          toast.error(
            'There was a problem when trying to submit the form. Please try again, or alternatively contact us directly.',
            {
              position: 'bottom-center',
              duration: 6000,
            }
          );
          setSubmitting(false);
        }
      } catch (error) {
        console.log('error', error);
      } finally {
      }
    },
    [form, formTitle, cardTitle]
  );

  return (
    <div
      className={`flex w-full flex-wrap items-center justify-normal gap-2 rounded-[10px] bg-charcoal-900 px-8 py-6 md:w-[50%] md:flex-wrap md:gap-0 xl:flex-nowrap xl:justify-between xl:gap-4 ${props.className}`}
    >
      <div className='xl:w-full'>
        <p className='select-none text-base text-yellow-50 md:text-sm/[12px] md:leading-6'>
          {props?.children ? (
            props?.children
          ) : (
            <span>
              <strong>Get a 10% discount</strong> when you subscribe to our
              monthly newsletter and get free tools to grow your business.
            </span>
          )}
        </p>
      </div>
      <div className='mt-2 flex w-full justify-between gap-6 xl:w-full xs:w-auto'>
        {form && !formResponse ? (
          <>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form
                  className='w-full sm:w-auto'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='mx-auto flex w-full flex-col items-center xs:flex-row'>
                    {form?.fieldGroups?.map((fieldGroup, index) => (
                      <div key={index} className='flex w-full items-center'>
                        {fieldGroup.fields?.map((field, index) => (
                          <div className='w-full' key={index}>
                            {field.fieldType.startsWith('email') && (
                              <div className='flex-shrink-1 gap-1xs:gap-0 flex flex-grow flex-col'>
                                <NewsletterEmailField
                                  containerProps={{
                                    className: '',
                                  }}
                                  disabled={formik.isSubmitting}
                                  type={field.hidden ? 'hidden' : 'email'}
                                  labelProps={{
                                    className: 'hidden',
                                  }}
                                  className={`focus:!border-white' -mr-[2px] flex w-full rounded-l-lg rounded-r-lg !border border-[#EFE9DB] !bg-charcoal !p-2 text-white placeholder:text-gray-800 xs:w-full xs:rounded-none xs:rounded-l-lg xs:rounded-r-none`}
                                  name={field.name}
                                  required={field.required}
                                  placeholder={field.placeholder ?? 'Email'}
                                  {...(field.fieldType.startsWith('number') && {
                                    min: field.validation?.minAllowedDigits,
                                    max: field.validation?.maxAllowedDigits,
                                  })}
                                  icon={
                                    field.description ? (
                                      <InputToolTip
                                        message={field.description}
                                      />
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}

                    <input type='hidden' name='firstname' value={''} />

                    <div className='flex-shrink-0'>
                      <Button
                        className='mx-auto mt-3 flex h-[45px] w-full flex-shrink-0 items-center justify-center rounded-none rounded-l-lg rounded-r-lg bg-[#EFE9DB] px-8 py-1.5 text-center text-sm/[12px] text-black hover:bg-white hover:text-black md:w-full md:min-w-[102px] md:px-8 md:py-3 xl:min-w-fit xs:mx-0 xs:mt-0 xs:w-auto xs:rounded-l-none'
                        type='submit'
                      >
                        <span>
                          {form?.displayOptions?.submitButtonText || 'Submit'}
                        </span>
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <div className='text-white'>Loading...</div>
        )}

        {formResponse && formResponse.inlineMessage && (
          <div>{formResponse.inlineMessage}</div>
        )}
      </div>
    </div>
  );
}
