import { componentMap } from '@/components/sections';
import LazyMotionWrapper from '@/components/shared/LazyMotionWrapper';

import React, { Suspense } from 'react';

export default function ComponentRenderer({
  sections,
  breadcrumbs,
  children,
  map,
}: {
  sections: any;
  breadcrumbs: any;
  children?: any;
  map?: any;
}) {
  const actualMap = map || componentMap;
  return (
    <>
      <LazyMotionWrapper>
        {children}
        {sections &&
          sections.map((section: any, index: number) => {
            const Component = actualMap[section._type];

            if (!Component) return null;

            return index > 2 ? (
              <Suspense
                key={`component-${section._type}-${section.id ? section.id : section._id}`}
                fallback={<div>Loading...</div>}
              >
                <Component data={{ breadcrumbs, ...section }} />
              </Suspense>
            ) : (
              <Component
                key={section.id ? section.id : section._id}
                data={{ breadcrumbs, ...section }}
              />
            );
          })}
      </LazyMotionWrapper>
    </>
  );
}
